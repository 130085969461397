@import "../../theme.scss";

#app-head {
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(230, 234, 241, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  .logo {
    height: 70px;
    padding-left: 15px;

    h1 {
      height: 100%;
      text-align: center;

      a {
        display: inline-block;
        vertical-align: middle;
        width: 125px;
        height: 32px;
        background: url('../../images/logo.png') no-repeat;
        // background-size: auto 100%;
        text-indent: -999px;
        @media screen and (max-width: 1200px) {
          width: 35px;
          background: url('../../images/logo-icon.png') no-repeat;
          background-size: auto 100%;
        }
      }

      &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }
  }

  .siderNav,.head_left {
    height: 70px;
  }


  .buy-btn {
    float: right;
    height: 100%;
    background: $bg-color;

    a {
      display: block;
      padding: 0 20px;
      height: 70px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 70px;

      i {
        margin-right: 14px;
      }
    }
  }

  .headerbar {
    float: right;
  }

  .head_tab_left {
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }

  .head_tab_conter {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .head_tab_right {
    height: 70px;
    display: flex;
    align-items: center;
    padding-right: 15px;
    .head_tab_right_btn{
      margin-left: auto;
      cursor: pointer;
    }
    .head_lang_r{
      margin-left: auto;
    }
  }

  .title {
    height: 70px;
    position: relative;
    margin-left: 30px;
    text-align: left;
    p {
      font-size: 18px;
      color: rgba(0, 0, 0, 1);
      line-height: 70px;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 20px;
      left: -34px;
      width: 1px;
      height: 32px;
      background: rgba(233, 233, 233, 1);
    }
  }
}
