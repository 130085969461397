@import "../theme.scss";

//Operation
.operation {
  display: flex;
  background: white;
  border-radius: 3px;
  border: 1px solid #ebebeb;
  padding: 4px 15px;
  align-items: center;

  .checkAll {
    display: flex;
    align-items: center;
    padding: 5px 0;
    height: 26px;

    >i {
      margin-right: 5px;
      cursor: pointer;
      font-size: 18px;
      border-radius: 4px;
    }

    .checkAll-text {
      // margin-right: 10px;
      color: #020202;
    }

    .invert-Selection {
      cursor: pointer;
      color: $bg-color;
      margin-left: 48px;
      position: relative;

      &::before {
        position: absolute;
        display: block;
        content: '';
        width: 1px;
        height: 22px;
        background-color: #8d8d8d;
        top: 50%;
        left: -24px;
        margin-top: -11px;
        opacity: 0.35;
      }
    }

    .cancel {
      margin-left: 48px;
      padding: 0 8px;

      i {
        margin: 0;
        color: $bg-color;
      }

      &::before {
        position: absolute;
        display: block;
        content: '';
        width: 1px;
        height: 22px;
        background-color: #8d8d8d;
        top: 50%;
        left: -24px;
        margin-top: -11px;
        opacity: 0.35;
      }
    }

    @media screen and (max-width:800px) {
      padding: 0;

      .invert {
        display: none;
      }

      .invert-Selection {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .btn {
    position: relative;
    margin-left: 48px;
    height: 26px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 22px;
      background-color: #8d8d8d;
      top: 50%;
      left: -24px;
      margin-top: -11px;
      opacity: 0.35;
    }
  }

  @media screen and (max-width:597px) {
    .checkAll .checkAll-text {
      display: none;
    }
  }

  @media screen and (max-width:480px) {

    .checkAll .cancel,
    .btn {
      margin-left: 18px;

      &::before {
        left: -11px;
      }
    }


  }
}


//ListCell
.PlaylistCell {
  float: left;
  padding: 5px;

  .list_cell {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 2px solid #fff;
    box-shadow: 0px 2px 6px 0px rgba(210, 222, 241, 1);
    background: rgba(255, 255, 255, 1);
    position: relative;
    outline: none;

    .top_img {
      height: 58%;

      .album_top_img {
        height: 100%;
        position: relative;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        overflow: hidden;
      }

      .top_topImg {
        position: relative;
        overflow: hidden;
        height: 66%;
        background-color: #f3f5f7;
        background-repeat: no-repeat;
        background-position: center;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      .top_bottomImgs {
        height: 34%;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;

        .img1,
        .img2,
        .img3 {
          float: left;
          width: 33.3%;
          height: 100%;
          background-color: #f3f5f7;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          overflow: hidden;
        }

        .img1,
        .img2 {
          // margin-right: .5%;
          border-right: 1px solid #e7e7e7;
        }
      }
    }

    .information {
      flex: 1;
      padding: 0 5px;
      position: relative;
      display: flex;
      flex-direction: column;

      .information_top {
        flex: 53%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-bottom: 1px solid #e7e7e7;

        .name {
          width: 140px;
          font-size: 16px;
          color: rgba(0, 0, 0, 1);
        }

        .time {
          position: relative;

          .time-span {
            display: block;
            float: left;
            background: rgba(243, 243, 243, 1);
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(144, 144, 144, 1);
            padding: 0 7px;

            @media screen and (max-width:500px) {
              padding: 0;
            }
          }
        }
      }

      .information_bottom {
        flex: 47%;

        span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.65);
        }

        .information_bottom2 {
          height: 100%;
          display: flex;
          padding-top: 3px;
          align-items: center;

          .photo-num {
            margin-right: 10px;
          }

          .num1 {
            font-size: 14px;
            font-weight: 500;
            color: rgba(25, 25, 25, 1);
            text-align: center;
            margin-bottom: 2px;
          }

          .text1 {
            font-size: 13px;
            font-weight: 400;
            color: rgba(25, 25, 25, 1);
            opacity: 0.3;
          }

          .more {
            margin-left: auto;
            border-radius: 3px;
            height: 20px;
            align-self: center;

            &:hover {
              background-color: $hover;
            }
          }
        }

      }

      .frames_def {
        position: absolute;
        top: 7px;
        right: 5px;
        display: block;
        width: 44px;
        height: 18px;
        background: $bg-color;
        border-radius: 3px;
        font-size: 12px;
        color: white;
        line-height: 18px;
        text-align: center;
      }

      .frames_isdefault {
        position: absolute;
        top: 0;
        right: 0;
        background: $bg-color;
        border-radius: 3px;
        font-size: 12px;
        color: white;
        line-height: 18px;
        padding: 0 2px;
      }

      .frame-play {
        position: absolute;
        top: 3px;
        right: 5px;

        i {
          font-size: 28px;
          color: $bg-color;
          cursor: pointer;
        }
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 100%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      border: 2px solid $bg-color !important;
    }

    &:hover .icon-check-s {
      visibility: initial;
    }

    .DropUpList {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.45);
      transition: 'border .24s ease-in-out';
      z-index: 1000;
    }

    .DropUpList_box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed #eee;
      background-color: rgba(0, 0, 0, 0.45);
      transition: 'border .24s ease-in-out';
      z-index: 1000;

      p {
        color: #fff;
        font-size: 24px;
      }
    }
  }

  .list_cell_check {
    border: 2px solid $bg-color;

    .icon-check-s {
      visibility: initial;
    }
  }
}

.ListCell_menuItem {
  line-height: 30px;
  color: rgba(2, 2, 2, 1);
}


//NewBtn
.new_btn_inner {
  padding: 8px 15px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(235, 235, 235, 1);
  display: flex;
  align-items: center;

  .new_btn button {
    padding: 0 20px;
    height: 40px;
    background: $bg-color;
    border-radius: 3px;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 20px;
    cursor: pointer;
  }

  .new_btn_right {
    display: flex;
    align-items: center;
    margin-left: 50px;

    .new_Icon i {
      font-size: 32px;
      margin-right: 10px;
    }

    .num-text {
      .text {
        margin: 0;
        font-size: 13px;
        color: rgba(1, 1, 1, 0.6);
      }

      .num {
        font-weight: 500;
        color: rgba(25, 25, 25, 1);
        line-height: 24px;
      }
    }
  }
}


//Sorter
.sort_inner {
  position: relative;

  &:hover {
    background: $hover;
  }

  .sort {
    display: block;
    border: 1px solid rgba(191, 191, 191, 1);
    border-radius: 3px;
    padding: 0 10px;
    height: 26px;
    line-height: 26px;

    i {
      margin-left: 10px;
    }
  }

  .sort-list {
    width: 240px;
    position: absolute;
    left: 0;
    top: 35px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: 1px solid rgba(233, 233, 233, 1);
    padding: 10px 0;
    z-index: 999;

    ul li {
      height: 30px;
      line-height: 30px;
      color: rgba(2, 2, 2, 1);
      cursor: pointer;

      &:hover {
        background: $hover;
      }

      i {
        visibility: hidden;
        margin: 0 15px;
      }

      .check-icon {
        visibility: initial;
        color: $bg-color;
      }
    }
  }
}


//DropUp
.DropUp_inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px dashed #eee;
  background-color: rgba(0, 0, 0, 0.45);
  transition: 'border .24s ease-in-out';
  z-index: 9999;

  p {
    color: #fff;
    font-size: 24px;
  }
}

//FrameCell
.upfile {
  position: relative;

  input {
    position: absolute;
    height: 42px;
    right: 0;
    top: 0;
    opacity: 0;
  }
}

.FrameCell {
  float: left;
  padding: 5px;

  .rahmen-list {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px #ebebeb;
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 4px;
    cursor: pointer;

    .rahmen-cover {
      position: relative;
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e4e4e4;

      .cover-img {
        position: relative;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
        padding: 10px 8px;
        overflow: hidden;

        .back-img {
          display: block;
          border: 1px solid #e9e9e9;
          height: 100%;
          width: 100%;
          background-image: url("../images/framelogo.png");
          background-position: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .frame-ringht-500 {
        position: absolute;
        right: 7px;
        bottom: 10px;
        display: none;

        span {
          position: absolute;
          display: block;
          width: 48px;
          height: 24px;
          text-align: center;
          background: $bg-color;
          border-radius: 3px;
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
          top: 0;
        }

        i {
          color: $bg-color;
          font-size: 28px;
          cursor: pointer;
        }

        @media screen and (max-width:500px) {
          display: block;
        }
      }
    }

    .rahmen-name-series {
      flex: 1;
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;

      p {
        margin: 0;
      }

      .name_info {
        height: 21px;

        .name {
          display: inline-block;
          width: 70%;
          font-size: 16px;
          color: rgba(0, 0, 0, 1);
          line-height: 21px;
        }

        .admin {
          float: right;
          background-color: $bg-color;
          color: #fff;
          font-size: 14px;
          border-radius: 3px;
        }
      }

      .rahmen_bottom {
        display: flex;

        .series {
          height: 21px;
          color: rgba(179, 180, 183, 1);
          line-height: 21px;

          @media screen and (max-width:500px) {
            font-size: 12px;
          }
        }

        .more {
          margin-left: auto;
          border-radius: 3px;
          height: 20px;
          align-self: center;

          &:hover {
            background-color: $hover;
          }
        }
      }

      .frame-ringht {
        position: absolute;
        right: 15px;
        bottom: 10px;

        span {
          position: absolute;
          display: block;
          width: 48px;
          height: 24px;
          text-align: center;
          background: $bg-color;
          border-radius: 3px;
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
          top: 0;
        }

        i {
          color: $bg-color;
          font-size: 28px;
          cursor: pointer;
        }

        @media screen and (max-width:500px) {
          display: none;
        }
      }
    }

    &:hover {
      border-color: $bg-color;
    }

    &:hover .icon-check-s {
      visibility: initial;
    }
  }

  .rahmen-list-check {
    border-color: $bg-color;

    .icon-check-s {
      visibility: initial;
    }
  }
}


//SmallPlaylist
.list_BOX {
  padding: 5px;

  .list-box {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(210, 222, 241, 1);
    cursor: pointer;

    .list-box-check {
      position: absolute;
      display: block;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 1);
      border-radius: 3px;
      border: 2px solid $icon;

      i {
        vertical-align: top;
        color: #fff;
        visibility: hidden;
      }
    }

    .top-img {
      .img1 {
        height: 65%;
        background: url('../images/imgerror.png') no-repeat;
        background-position: center center;
        background-size: 100% 130%;
        background-color: #f3f5f7;
        margin-bottom: 1px;
        text-align: center;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 2px 2px 0 0;
        }
      }

      .img2-4 {
        display: flex;
        height: 35%;
        justify-content: space-around;

        .img2 {
          flex: 30%;
          background: url('../images/imgerror.png') no-repeat;
          background-color: #f3f5f7;
          background-size: 100% 100%;
          background-position: center center;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .img3 {
          flex: 30%;
          background: url("../images/imgerror.png") no-repeat;
          background-color: #f3f5f7;
          background-size: 100% 100%;
          background-position: center center;
          margin: 0 1px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .img4 {
          flex: 30%;
          background: url("../images/imgerror.png") no-repeat;
          background-color: #f3f5f7;
          background-size: 100% 100%;
          background-position: center center;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .bottom-text {
      position: relative;
      padding: 0 10px;

      .playlist-name {
        width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 19px;
        font-size: 14px;
        color: rgba(0, 0, 0, 1);
        line-height: 19px;
        margin: 11px 0;
      }

      .update-time {
        overflow: hidden;
        margin-bottom: 10px;

        span {
          display: block;
          float: left;
          height: 21px;
          background: rgba(243, 243, 243, 1);
          border-radius: 3px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(144, 144, 144, 1);
          line-height: 19px;
          padding: 0 7px;
        }
      }

      .playlist-photo-num {
        position: absolute;
        top: 13px;
        right: 10px;
        font-size: 12px;

        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .list-checked {
    border: 2px solid $bg-color;

    .list-box-check {
      background: $bg-color;
      border-color: $bg-color;

      i {
        visibility: initial;
      }
    }
  }
}


//Goblack
.go-back {
  .go-back-on {
    display: inline-block;
    margin: 0;
    width: 100%;
    padding: 5px 0;
    height: 37px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);

    .playlist-name {
      display: inline-block;
      height: 27px;
      max-width: calc(100% - 42px);
      line-height: 27px;
      cursor: pointer;

      i {
        float: left;
        font-size: 14px;
        padding-bottom: 4px;
        line-height: 30px;
        margin-right: 18px;
      }
    }
  }
}


//Sortbox
.sortbox {
  position: relative;
  height: 30px;
  align-self: center;
  border-left: 1px solid #e9e9e9;
  padding-left: 24px;
  margin-right: 24px;
  display: flex;

  .sort {
    align-self: center;
    border-radius: 3px;
    border: 1px solid rgba(191, 191, 191, 1);
    padding: 0 10px;
    cursor: pointer;

    i {
      margin-left: 10px;
    }

    &:hover {
      background: rgba(255, 224, 212, 1);
    }
  }

  .sort-list {
    width: 250px;
    position: absolute;
    top: 27px;
    right: 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: 1px solid rgba(233, 233, 233, 1);
    padding: 12px 0;
    z-index: 9;

    ul li {
      cursor: pointer;
    }

    ul li span {
      display: block;
      height: 30px;
      font-size: 14px;

      color: rgba(2, 2, 2, 1);
      line-height: 30px;
      padding-left: 15px;

      i {
        visibility: hidden;
        margin-right: 15px;
      }

      .check-icon {
        visibility: initial;
        color: $icon;
      }
    }

    ul li:hover {
      background: rgba(255, 224, 212, 1);
    }
  }
}