@import "./theme.scss";

@mixin com {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 576px) {
  #page {
    .home .overview-inner {
      .overview-right {
        display: flex;
      }
      .home-frame {
        display: flex;
      }
    }
  }
}

/*>=768的设备*/

@media (max-width: 768px) {
  #page {
    .banner-img {
      display: none;
    }

    .overview-inner {
      .overview-right {
        display: none;
      }
      .home-frame {
        display: none;
      }
    }
  }
}

/*>=768的设备*/

@media (max-width: 1110px) {
  #page {

    .home {
      .banner-img {
        position: absolute;
        width: 300px;
        height: 196px;
        right: 45px;
        bottom: 0;
      }
    }
  }
}

/*<=950的设备*/
@media screen and (max-width: 950px) {
  #page {
    .overview-inner {
      .overview-right {
        @include com;

        >div:nth-child(3) {
          display: none;
        }

        >div:nth-child(4) {
          display: none;
        }
      }
      .home-frame {
        flex: 1;

        >div:nth-child(2) {
          display: none;
        }
      }
    }



    .container {
      .login-mod .login-inner {
        left: 30% !important;
      }
    }
  }
}

/*>=950的设备*/
@media (min-width: 951px) {
  #page {
    .playlistdetails .playlist-set {
      display: none;
    }
  }
}

/*<=1220的设备*/
@media (max-width: 1233px) {
  #page {
    .home {
      width: 100%;

      .bindframe-btn::after,
      .bindframe-btn::before {
        width: 0;
      }

      .bindframe-btn {
        margin-left: 40px;
      }
    }
  }
}